<template>
  <div
    :class="[baseClass + '-mobile-type-item']"
    @click="$router.push(`/mobile/type/${item}`)"
    v-if="item"
  >
    <div :class="[baseClass + '-mobile-type-item-content']">
      <img class="img" :src="getImg(item)" />
    </div>
  </div>
</template>

<script>
const imgKeyHash = {
  ".IO": "IO",
  "3D": "3D ",
  Sports: "Sport",
  Boys: "Boy",
  // 'Puzzles': 'Puzzle',
  Girls: "Girl",
  Arcade: "Arcide",
};
export default {
  props: ["item"],
  methods: {
    getImg(item) {
      let str = imgKeyHash[item] ? imgKeyHash[item] : item;
      let type = this.isPc ? "web" : "phone";
      return require("@/assets/types/sort／" + type + "／" + str + "@2x.png");
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-mobile-type-item {
  width: calc((100vw - (15px * 2) - 13px) / 2);
  &-content {
    cursor: pointer;
    width: 100%;
    height: calc((100vw - (15px * 2) - 13px) / 4);
    margin-bottom: 20px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
