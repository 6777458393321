<template>
  <div :class="baseClass + '-mobile-type-page'">
    <div :class="baseClass + '-mobile-type-page-title'">All Catrgores</div>
    <div :class="baseClass + '-mobile-type-page-content'">
      <Type
        v-for="(item, index) of $store.state.typeList"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>
<script>
import Type from "@/components/MobileTypeItem.vue";
export default {
  components: { Type },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss">
.#{$baseClass}-mobile-type-page {
  padding: 0 15px;
  &-title {
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #ffffff;
    padding: 10px 0;
    margin-bottom: 13px;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
